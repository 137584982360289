<template>
  <router-layout>
    <div class="page">
      <div class="form">
        <div class="form_t">基本信息</div>
        <div class="input">
          <van-field v-model="type" input-align="right" readonly required label="类别" />
        </div>
        <div class="input">
          <van-field
            v-model="title"
            clearable
            input-align="right"
            required
            label="标题"
            placeholder="请输标题,40字以内"
            maxlength="40"
          />
        </div>
        <div class="input">
          <van-field
            v-model="area"
            input-align="right"
            is-link
            readonly
            required
            label="区域"
            placeholder="请选择"
            @click="areaPicker"
          />
        </div>
        <div class="input noborder">
          <van-field
            v-model="time"
            input-align="right"
            is-link
            readonly
            required
            label="有效期"
            placeholder="请选择"
            @click="timePicker"
          />
        </div>
        <div class="line"></div>
        <div class="input">
          <van-field v-model="name" input-align="right" required label="联系人" placeholder="请输入" />
        </div>
        <div class="input">
          <van-field
            v-model="phone"
            input-align="right"
            required
            label="手机"
            placeholder="请输入"
            type="number"
            maxlength="11"
          />
        </div>
        <div class="input noborder">
          <div class="input_t">详细描述</div>
          <van-field
            v-model="detail"
            rows="4"
            autosize
            type="textarea"
            maxlength="300"
            placeholder="可以详细描述您的推广信息"
            show-word-limit
            clearable
          />
        </div>
        <div class="line"></div>
        <div class="input noborder">
          <div class="input_t">照片</div>
          <div class="photo">
            <van-uploader
              v-model="photo"
              multiple
              :max-count="2"
              :max-size="imgMaxSize"
              @oversize="onOversize"
              @delete="deleteImg"
              :after-read="uploadReady"
            />
          </div>
        </div>
        <!--  -->
      </div>
      <div class="submit" @click="submit">确定发布</div>
      <!-- 区域弹窗 -->
      <van-popup v-model="areaPickerShow" position="bottom">
        <van-picker
          title="区域"
          show-toolbar
          :default-index="areaIndex"
          :columns="areaPick"
          @cancel="areaCancel"
          @confirm="areaConfirm"
        />
      </van-popup>
      <!-- 有效期弹窗 -->
      <van-popup v-model="timePickerShow" position="bottom">
        <van-picker
          title="有效期"
          show-toolbar
          :default-index="timeIndex"
          :columns="timePick"
          @cancel="timeCancel"
          @confirm="timeConfirm"
        />
      </van-popup>
    </div>
  </router-layout>
</template>

<script>
import authMixin from "@/mixin/mixin";
export default {
  data() {
    return {
      type_id:'',
      type:'',
      imgMaxSize: 5 * 1024 * 1024, // 最大上传图片大小5M
      mediumquality: 0.7,
      title: "",
      area_id: "",
      area: "",
      areaIndex: "",
      areaPickerShow: false,
      areaPick: [{ id: "0", text: "不限" }],
      time_id: "",
      time: "",
      timeIndex: "",
      timePickerShow: false,
      timePick: [
        { id: "0", text: "长期有效" },
        { id: "1", text: "一周" },
        { id: "2", text: "一个月" },
        { id: "3", text: "两个月" },
        { id: "4", text: "一年" }
      ],
      detail: "", //自我描述
      name: "",
      phone: "",
      photo: [],
      photoUrl: [],
      imgs: ""
    };
  },
  mixins: [authMixin],
  created() {
    this.getRouteQuery();
    this.getArea();
  },
  methods: {
    getRouteQuery() {
      this.typeIndex = this.$route.query.value;
      this.type = this.$route.query.jobType;
      this.type_id = this.$route.query.id;
    },
    onOversize(file) {
      this.$toast("图片大小不能超过5M");
    },
    uploadReady(file) {
      // console.log(file.file)
      // if( file.file.size > this.imgMaxSize ){
      //   //压缩图片
      // }
      //图片选择回调
      let formData = new FormData();
      formData.append("img", file.file);
      this.$request({
        method: "post",
        url: "/cms/upload/",
        data: formData,
        headers: {
          "Content-Type": "multipart/form-data"
        }
      }).then(res => {
        console.log(res);
        if (res.data) {
          this.photoUrl.push(res.data);
        }
      });
    },
    deleteImg(val, index) {
      //图片删除回调
      let deleIndex = index.index;
      let deleImg = this.photoUrl[deleIndex]; //删除的服务器上对应图片
      this.photoUrl.splice(deleIndex, 1);
      this.$request({
        method: "post",
        url: "/cms/deleteImg",
        data: {
          img: deleImg
        }
      }).then(res => {
        console.log(res);
      });
    },
    areaPicker() {
      this.areaPickerShow = true;
    },
    areaCancel() {
      this.areaPickerShow = false;
    },
    areaConfirm(item, index) {
      this.areaPickerShow = false;
      this.areaIndex = String(index);
      this.area = item.text;
      this.area_id = item.id;
    },
    timePicker() {
      this.timePickerShow = true;
    },
    timeCancel() {
      this.timePickerShow = false;
    },
    timeConfirm(item, index) {
      this.timePickerShow = false;
      this.timeIndex = String(index);
      this.time = item.text;
      this.time_id = item.id;
    },
    getArea() {
      this.$request({
        method: "get",
        url: "/cms/area/"
      }).then(res => {
        if (res.data && res.data.length) {
          res.data.forEach(item => {
            let objdata = `{
                "id": "${item.id}",
                "text": "${item.title}"
               }`;
            this.areaPick.push(JSON.parse(objdata));
          });
        }
      });
    },
    subTxtData() {
      let imgs = this.photoUrl.join(',')
      this.$request({
        method: "post",
        url: "/cms/promote/",
        data: {
          tid: this.type_id,
          title: this.title,
          area_id: this.area_id,
          valid_time: this.time_id,
          name: this.name,
          phone: this.phone,
          description: this.detail,
          imgs: imgs
        }
      }).then(res => {
        console.log(res);
        if (res.code == 200) {
          this.$toast.clear();
          this.$toast.success({
            message: "发布成功,请等待审核",
            forbidClick: true,
            duration: 500
          });
          setTimeout(() => {
            this.$router.replace({
              path:"/PromoteList",
              query:{
                infoType: '4'
              }
            });
          }, 700);
        }
      });
    },
    submit() {
      let reg = /^[1][3,4,5,7,8][0-9]{9}$/; //手机号正则验证
      let title = this.title;
      let area = this.area;
      let time = this.time;
      let name = this.name;
      let phone = this.phone;
      if (!title) {
        this.$toast({
          message: "请输入标题",
          forbidClick: true
        });
      } else if (!area) {
        this.$toast({
          message: "请选择区域",
          forbidClick: true
        });
      } else if (!time) {
        this.$toast({
          message: "请选择有效期",
          forbidClick: true
        });
      } else if (!name) {
        this.$toast({
          message: "请输入联系人",
          forbidClick: true
        });
      } else if (!phone) {
        this.$toast({
          message: "请输入手机号",
          forbidClick: true
        });
      } else if (!reg.test(phone)) {
        this.$toast({
          message: "请输入正确的手机号",
          forbidClick: true
        });
      } else {
        // this.$toast.loading({
        //   loadingType: "spinner",
        //   forbidClick: true,
        //   duration: 0
        // });
        this.subTxtData();
        //发送请求
      }
      // submit End
    }
  }
};
</script>
<style scoped lang="scss">
@import "../../../assets/iconfont/iconfont.css";
.submit {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  text-align: center;
  background: #567cf3;
  color: #fff;
  height: 1.1rem;
  line-height: 1.1rem;
  font-size: 0.4rem;
}
.form {
  padding-bottom: 1.5rem;
  .line {
    background: #f5f5f5;
    height: 0.2rem;
  }
  .form_t {
    background: #f5f5f5;
    color: #9e9e9e;
    font-size: 0.32rem;
    padding: 0.2rem 0.3rem;
    letter-spacing: 0.03rem;
  }
  .input {
    border-bottom: 1px solid #ebedf0;
    .input_t {
      color: #646566;
      padding: 0.26667rem 0.42667rem 0.05rem 0.42667rem;
      font-size: 0.37333rem;
    }
    .checkBox {
      display: flex;
      justify-content: flex-start;
      padding: 0.26667rem 0.42667rem 0.3rem 0.42667rem;
      .checkItem {
        background: #f6f6f6;
        color: #999;
        text-align: center;
        height: 0.9rem;
        line-height: 0.9rem;
        padding: 0 0.25rem;
        border-radius: 0.85rem;
        margin-top: 0.15rem;
        margin-bottom: 0.15rem;
        margin-right: 0.2rem;
        .van-checkbox {
          height: 100%;
          width: 100%;
        }
      }
      .checkItem.active {
        background: #567cf3;
      }
    }
    .photo {
      padding: 0.26667rem 0.42667rem 0.05rem 0.42667rem;
    }
  }
  .input.noborder {
    border-bottom: none;
  }
}
</style>